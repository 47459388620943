"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _gatsby = require("gatsby");

var _catchLinks = _interopRequireDefault(require("./catch-links"));

exports.onClientEntry = function () {
  (0, _catchLinks.default)(window, function (href) {
    (0, _gatsby.navigate)(href);
  });
};