import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.is-array";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.define-property";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import { MDXProvider } from '@mdx-js/react';
import deepmerge from 'deepmerge';
import isPropValid from '@emotion/is-prop-valid';
import React, { forwardRef, useContext, createContext, useState, useEffect, useReducer } from 'react';
import { jsx, ThemeContext, Global } from '@emotion/core';
import css, { css as css$1, get } from '@styled-system/css';
export { css, get } from '@styled-system/css';

var getCSS = function getCSS(props) {
  if (!props.sx && !props.css) {
    return undefined;
  }

  return function (theme) {
    var styles = css(props.sx)(theme);
    var raw = typeof props.css === 'function' ? props.css(theme) : props.css;
    return [styles, raw];
  };
};

var parseProps = function parseProps(props) {
  if (!props) {
    return null;
  }

  var next = {};

  for (var key in props) {
    if (key === 'sx') {
      continue;
    }

    next[key] = props[key];
  }

  next.css = getCSS(props);
  return next;
};

var jsx$1 = function jsx$$1(type, props) {
  var arguments$1 = arguments;

  for (var _len = arguments.length, children = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    children[_key - 2] = arguments$1[_key];
  }

  return jsx.apply(undefined, [type, parseProps(props)].concat(children));
};

function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

var canUseSymbol = typeof Symbol === 'function' && Symbol["for"];
var REACT_ELEMENT = canUseSymbol ? Symbol["for"]('react.element') : 0xeac7;
var FORWARD_REF = canUseSymbol ? Symbol["for"]('react.forward_ref') : 0xeac7;

var isMergeableObject = function isMergeableObject(n) {
  return !!n && _typeof(n) === 'object' && n.$$typeof !== REACT_ELEMENT && n.$$typeof !== FORWARD_REF;
};

var arrayMerge = function arrayMerge(destinationArray, sourceArray, options) {
  return sourceArray;
};

var merge = function merge(a, b) {
  return deepmerge(a, b, {
    isMergeableObject: isMergeableObject,
    arrayMerge: arrayMerge
  });
};

merge.all = function () {
  var arguments$1 = arguments;

  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments$1[_key];
  }

  return deepmerge.all(args, {
    isMergeableObject: isMergeableObject,
    arrayMerge: arrayMerge
  });
};

var styled = function styled(tag) {
  return function () {
    var arguments$1 = arguments;

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments$1[_key];
    }

    var shouldForwardProps = typeof tag === 'function';
    var Styled = forwardRef(function (_ref, ref) {
      var as = _ref.as,
          props = _objectWithoutProperties(_ref, ["as"]);

      var theme = useContext(ThemeContext);
      var nextProps = shouldForwardProps ? props : {};
      var styles = {};
      args.forEach(function (arg) {
        var style = typeof arg === 'function' ? arg(_objectSpread2({
          theme: theme
        }, props)) : arg;
        Object.assign(styles, style);
      });

      if (!shouldForwardProps) {
        for (var key in props) {
          if (!isPropValid(key)) {
            continue;
          }

          nextProps[key] = props[key];
        }
      }

      return jsx$1(as || tag, _objectSpread2({}, nextProps, {
        ref: ref,
        css: styles
      }));
    });
    return Styled;
  };
};

var themed = function themed(key) {
  return function (props) {
    return css$1(get(props.theme, "styles.".concat(key)))(props.theme);
  };
};

var tags = ['p', 'b', 'i', 'a', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img', 'pre', 'code', 'ol', 'ul', 'li', 'blockquote', 'hr', 'em', 'table', 'tr', 'th', 'td', 'em', 'strong', 'delete', // mdx
'inlineCode', 'thematicBreak', // other
'div', // theme-ui
'root'];
var aliases = {
  inlineCode: 'code',
  thematicBreak: 'hr',
  root: 'div'
};

var alias = function alias(n) {
  return aliases[n] || n;
};

var Styled = styled('div')(themed('div'));
var components = {};
tags.forEach(function (tag) {
  components[tag] = styled(alias(tag))(themed(tag));
  Styled[tag] = components[tag];
});

var createComponents = function createComponents() {
  var components = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var next = {};
  Object.keys(components).forEach(function (key) {
    next[key] = styled(components[key])(themed(key));
  });
  return next;
};

var Context = createContext({
  theme: {},
  components: components
});

var useThemeUI = function useThemeUI() {
  return useContext(Context);
};

var toVarName = function toVarName(key) {
  return "--theme-ui-".concat(key);
};

var toVarValue = function toVarValue(key, value) {
  return "var(".concat(toVarName(key), ", ").concat(value, ")");
};

var join = function join() {
  var arguments$1 = arguments;

  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments$1[_key];
  }

  return args.filter(Boolean).join('-');
};

var numberScales = {
  fontWeights: true,
  lineHeights: true
};
var reservedKeys = {
  useCustomProperties: true,
  initialColorMode: true
};

var toPixel = function toPixel(key, value) {
  if (typeof value !== 'number') {
    return value;
  }

  if (numberScales[key]) {
    return value;
  }

  return value + 'px';
}; // convert theme values to custom properties


var toCustomProperties = function toCustomProperties(obj, parent, themeKey) {
  var next = Array.isArray(obj) ? [] : {};

  for (var key in obj) {
    var value = obj[key];
    var name = join(parent, key);

    if (value && _typeof(value) === 'object') {
      next[key] = toCustomProperties(value, name, key);
      continue;
    }

    if (reservedKeys[key]) {
      next[key] = value;
      continue;
    }

    var val = toPixel(themeKey || key, value);
    next[key] = toVarValue(name, val);
  }

  return next;
};

var objectToVars = function objectToVars(parent, obj) {
  var vars = {};

  for (var key in obj) {
    if (key === 'modes') {
      continue;
    }

    var name = join(parent, key);
    var value = obj[key];

    if (value && _typeof(value) === 'object') {
      vars = _objectSpread2({}, vars, {}, objectToVars(name, value));
    } else {
      vars[toVarName(name)] = value;
    }
  }

  return vars;
}; // create body styles for color modes


var createColorStyles = function createColorStyles(theme) {
  if (!theme.colors || !theme.colors.modes) {
    return {};
  }

  if (!theme.useCustomProperties) {
    return css$1({
      color: 'text',
      bg: 'background'
    })(theme);
  }

  var modes = theme.colors.modes;
  var styles = objectToVars('colors', theme.colors);
  Object.keys(modes).forEach(function (mode) {
    var key = "&.theme-ui-".concat(mode);
    styles[key] = objectToVars('colors', modes[mode]);
  });
  return css$1(_objectSpread2({}, styles, {
    color: function color(t) {
      return "var(--theme-ui-colors-text, ".concat(t.colors.text, ")");
    },
    bg: function bg(t) {
      return "var(--theme-ui-colors-background, ".concat(t.colors.background, ")");
    }
  }))(theme);
};

var STORAGE_KEY = 'theme-ui-color-mode';
var storage = {
  get: function get$$1(init) {
    return window.localStorage.getItem(STORAGE_KEY) || init;
  },
  set: function set(value) {
    return window.localStorage.setItem(STORAGE_KEY, value);
  }
};

var getMediaQuery = function getMediaQuery() {
  var darkQuery = '(prefers-color-scheme: dark)';
  var mql = window.matchMedia ? window.matchMedia(darkQuery) : {};
  var dark = mql.media === darkQuery;
  return dark && mql.matches;
};

var useColorState = function useColorState(theme) {
  var _useState = useState(theme.initialColorMode),
      _useState2 = _slicedToArray(_useState, 2),
      mode = _useState2[0],
      setMode = _useState2[1];

  useEffect(function () {
    // initialize
    var stored = storage.get();
    document.body.classList.remove('theme-ui-' + stored);
    var dark = getMediaQuery();

    if (!stored && dark) {
      return setMode('dark');
    }

    if (!stored || stored === mode) {
      return;
    }

    setMode(stored);
  }, []);
  useEffect(function () {
    if (!mode) {
      return;
    }

    storage.set(mode);
  }, [mode]);

  if (process.env.NODE_ENV !== 'production') {
    if (theme.colors && theme.colors.modes && Object.keys(theme.colors.modes).indexOf(theme.initialColorMode) > -1) {
      console.warn('The `initialColorMode` value should be a unique name' + 'and cannot reference a key in `theme.colors.modes`.');
    }
  }

  return [mode, setMode];
};

var useColorMode = function useColorMode(initialMode) {
  var _useThemeUI = useThemeUI(),
      colorMode = _useThemeUI.colorMode,
      setColorMode = _useThemeUI.setColorMode;

  if (typeof setColorMode !== 'function') {
    throw new Error("[useColorMode] requires the ThemeProvider component");
  }

  return [colorMode, setColorMode];
};

var bodyColor = function bodyColor(theme) {
  return {
    body: createColorStyles(theme)
  };
};

var ColorMode = function ColorMode() {
  return React.createElement(Global, {
    styles: bodyColor
  });
};

var mergeState = function mergeState(state, next) {
  return merge.all({}, state, next);
};

var applyColorMode = function applyColorMode(theme, mode) {
  if (!mode) {
    return theme;
  }

  var modes = get(theme, 'colors.modes', {});
  return merge.all({}, theme, {
    colors: get(modes, mode, {})
  });
};

var BaseProvider = function BaseProvider(_ref) {
  var context = _ref.context,
      components$$1 = _ref.components,
      children = _ref.children;
  var theme = context.theme;
  return jsx$1(ThemeContext.Provider, {
    value: theme
  }, jsx$1(MDXProvider, {
    components: components$$1
  }, jsx$1(Context.Provider, {
    value: context,
    children: children
  })));
};

var RootProvider = function RootProvider(_ref2) {
  var _ref2$theme = _ref2.theme,
      propsTheme = _ref2$theme === void 0 ? {} : _ref2$theme,
      components$$1 = _ref2.components,
      children = _ref2.children; // components are provided in the default Context

  var outer = useThemeUI();

  var _useColorState = useColorState(propsTheme),
      _useColorState2 = _slicedToArray(_useColorState, 2),
      colorMode = _useColorState2[0],
      setColorMode = _useColorState2[1];

  var _useReducer = useReducer(mergeState, propsTheme),
      _useReducer2 = _slicedToArray(_useReducer, 2),
      themeState = _useReducer2[0],
      setThemeState = _useReducer2[1];

  var theme = applyColorMode(themeState, colorMode);

  if (theme.useCustomProperties) {
    theme.colors = toCustomProperties(theme.colors, 'colors');
  }

  var context = {
    __THEME_UI__: true,
    colorMode: colorMode,
    setColorMode: setColorMode,
    components: _objectSpread2({}, outer.components, {}, createComponents(components$$1)),
    theme: theme,
    setTheme: setThemeState
  };
  useEffect(function () {
    window.__THEME_UI__ = context;
  }, [context.theme, context.colorMode]);
  return jsx$1(BaseProvider, {
    context: context,
    components: context.components,
    children: children
  });
};

var NestedProvider = function NestedProvider(_ref3) {
  var theme = _ref3.theme,
      components$$1 = _ref3.components,
      children = _ref3.children;
  var outer = useThemeUI();
  var context = merge.all({}, outer, {
    theme: theme
  });
  return jsx$1(BaseProvider, {
    context: context,
    components: createComponents(components$$1),
    children: children
  });
};

var ThemeProvider = function ThemeProvider(props) {
  var outer = useThemeUI();

  if (outer.__THEME_UI__) {
    return jsx$1(NestedProvider, props);
  }

  return jsx$1(RootProvider, props);
};

var Box = styled('div')({
  boxSizing: 'border-box',
  minWidth: 0
});
var Flex = styled(Box)({
  display: 'flex'
}); // root/page layout

var Layout = function Layout(props) {
  return jsx$1(Box, _objectSpread2({}, props, {
    sx: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      variant: 'styles.Layout'
    }
  }));
};

var Header = function Header(props) {
  return jsx$1(Box, _objectSpread2({
    as: 'header'
  }, props, {
    sx: {
      display: 'flex',
      variant: 'styles.Header'
    }
  }));
};

var Main = function Main(props) {
  return jsx$1(Box, _objectSpread2({
    as: 'main'
  }, props, {
    sx: {
      flex: '1 1 auto',
      variant: 'styles.Main'
    }
  }));
};

var Container = function Container(props) {
  return jsx$1(Box, _objectSpread2({}, props, {
    sx: {
      width: '100%',
      minWidth: 0,
      maxWidth: 1024,
      mx: 'auto',
      p: 4,
      variant: 'styles.Container'
    }
  }));
};

var Footer = function Footer(props) {
  return jsx$1(Box, _objectSpread2({
    as: 'footer'
  }, props, {
    sx: {
      display: 'flex',
      variant: 'styles.Footer'
    }
  }));
};

export { jsx$1 as jsx, ThemeProvider, Context, useThemeUI, ColorMode, useColorMode, Styled, components, Box, Flex, Layout, Header, Main, Container, Footer };