import { Notes, Appear, Head } from "mdx-deck";
import { Split } from "gatsby-theme-mdx-deck";
import DeckTheme from "../../../../../../theme.js";
import Wow from "../../../../../../wow2.png";
import Quotes from "../../../../../../Quotes";
import Demo from "../../../../../../Demo";
import "../../../../../../deck.css";
import Hexagon from "../../../../../../Hexagon";
import Intro from "../../../../../../Intro.js";
import Backstory from "../../../../../../Backstory.js";
import chart from "../../../../../../compare-cra-gatsby-next.png";
import React from 'react';
export default {
  Notes: Notes,
  Appear: Appear,
  Head: Head,
  Split: Split,
  DeckTheme: DeckTheme,
  Wow: Wow,
  Quotes: Quotes,
  Demo: Demo,
  Hexagon: Hexagon,
  Intro: Intro,
  Backstory: Backstory,
  chart: chart,
  React: React
};